
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { priceRulesEl } from "@/utils/verify";
import t from "@common/src/i18n/t";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";

const partsCategory = namespace("parts-category");
const parts = namespace("parts");
const base = namespace("base");
@Component({
  components: { GeneralVehicleSelect }
})
export default class partsEdit extends Vue {
  @partsCategory.Action getCategoryList;
  @parts.Action createParts;
  @parts.Action getPartsDetail;
  @parts.Action updateParts;
  @parts.Action getPartsBrandList;
  @parts.Action createBrand;
  @base.Action getDictionaryList;
  get breadData() {
    return [
      { name: t("parts.info"), path: "/management/parts-list" },
      {
        name: this.$route.name === "ManagementPartsAdd" ? t("setting.added-parts") : t("setting.editing-spare-parts")
      }
    ];
  }
  get isSystemSet() {
    return this.$route.query.isView;
  }
  partsFormData = {
    partsName: "",
    partsNumber: "",
    brandCode: "",
    categoryCode: "",
    purchasePrice: "",
    sellingPrice: "",
    specification: "",
    oemNumber: "",
    partsUnit: "",
    vehicleModelCodes: [],
    barcode: "",
    status: 1,
    source: 0,
    storeTypes: [],
    externalCode: ""
  };
  // selectedVehicleList: any[] = [];
  defaultVehicleList: any[] = [];
  partsUnitList: any[] = []; // 数据字典-单位
  categoryList: any[] = []; // 零配件分类数据
  brandList: any = [];
  storeTypeList: any = [];

  categoryCasProps: any = {
    label: "categoryName",
    value: "categoryCode"
  };
  brandListCopy: any[] = [];
  handleAreaChange() {}

  partsFormRules = {
    partsName: [{ required: true, message: t("setting.enter-name-parts"), trigger: "blur" }],
    categoryCode: [{ required: true, message: t("setting.select-parts-classification"), trigger: "blur" }],
    purchasePrice: [
      {
        validator: this.checkPrice,
        trigger: "blur"
      }
    ],
    sellingPrice: [
      {
        validator: this.checkPrice,
        trigger: "blur"
      }
    ],
    storeTypes: [{ required: true, message: t("v210906.please-select-store-type"), trigger: "blur" }]
  };
  get isAdd() {
    return this.$route.name === "ManagementPartsAdd";
  }
  created() {
    this.init();
  }
  checkPrice(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      priceRulesEl(value, callback);
    }
  }
  getBrandList(callback?) {
    this.getPartsBrandList().then(data => {
      this.brandList = data.data.list;
      this.brandListCopy = data.data.list;
      callback && callback();
    });
  }
  handleValidate(prop, validate, err) {
    if (validate && (prop === "purchasePrice" || prop === "sellingPrice")) {
      this.partsFormData[prop] = Number(this.partsFormData[prop]).toFixed(2);
    }
  }
  brandSearchValue: string = ""; // 品牌搜索keyword
  handleSearchBrand(val) {
    if (val) {
      this.brandSearchValue = val;
      this.brandList = this.brandList.filter(item => {
        return item.brandName.includes(val);
      });
    } else {
      this.brandList = this.brandListCopy;
    }
  }
  async addNewBrand() {
    (this.$refs["brandSelect"] as any).blur();
    const data = await this.createBrand({ brandName: this.brandSearchValue });
    this.getBrandList(() => {
      this.$nextTick(() => {
        this.partsFormData.brandCode = data.data.brandCode;
      });
    });
  }
  // 新增和编辑的时候将车型code集合添加到form中
  // addSeriesCodeToForm() {
  //   if (this.selectVehicleList && this.selectVehicleList[0]) {
  //     this.customerFormData.vehicle.modelCode = this.selectVehicleList[0].lastValue;
  //   }
  // }
  vehicleDataFormat(data) {
    return (
      data &&
      data.map(item => ({
        lastValue: item.modelCode,
        name: item.brandName + " / " + item.seriesName + " / " + item.modelDesc,
        valueGroup: [item.brandCode, item.seriesName, item.modelCode]
      }))
    );
  }
  submitForm(formName) {
    (this.$refs[formName] as any).validate(valid => {
      if (valid) {
        if (this.isAdd) {
          this.addParts();
        } else {
          this.editParts();
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }

  async init() {
    this.getDictionaryList("parts_unit").then(data => {
      this.partsUnitList = data;
    });
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    this.getBrandList();
    const categoryList = await this.getCategoryList();
    this.categoryList = this.addLeafFlag(categoryList.data);
    if (!this.isAdd) {
      const partsDetailData = await this.getPartsDetail({
        partsCode: this.$route.params.id
      });
      this.partsFormData = partsDetailData.data;
      this.defaultVehicleList = this.vehicleDataFormat(partsDetailData.data.vehicleModels);
    }
  }
  // 过滤空children
  addLeafFlag(list) {
    const f = function(list) {
      list.forEach(item => {
        if (!item.children || item.children.length < 1) {
          delete item.children;
        } else {
          f(item.children);
        }
      });
    };
    f(list);
    return list;
  }
  // 新增和编辑的时候将车型code集合添加到form中

  async addParts() {
    this.partsFormData.categoryCode = this.partsFormData.categoryCode.slice(-1)[0];
    await this.createParts(this.partsFormData);
    this.$message.success(t("base.create-success") as string);
    this.$router.push(`/management/parts-list`);
  }
  async editParts() {
    this.partsFormData.categoryCode =
      typeof this.partsFormData.categoryCode === "string"
        ? this.partsFormData.categoryCode
        : (this.partsFormData.categoryCode as []).slice(-1)[0];
    this.updateParts(this.partsFormData).then(data => {
      this.$message.success(t("base.update-success") as any);
      this.$router.push(`/management/parts-list`);
    });
  }
}
