
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
const base = namespace("base");
@Component({
  inheritAttrs: false
})
export default class VehicleSelect extends Vue {
  @Prop({ default: false, type: Boolean }) multiple: boolean;
  @Prop({ default: "model" }) selectType: string;
  @Prop({ default: "请选择" }) placeholder: string;
  @Prop({ default: () => [] }) defaultValue: any[];
  @Prop({ default: () => [] }) value: any[];
  @Prop({ default: () => [] }) formatValue: any[];
  @base.Action getvehicleTree;
  stepIndex: number = 0;
  brandList: any[] = [];
  brandListCody: any[] = [];
  renderList: any[] = [];
  seriesList: any[] = [];
  letterList: string[] = [
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];
  selectedFilterItem: any[] = [];
  selectedModelList: any[] = [];
  popoverVisible: boolean = false;
  currentBrandLetter: string = "all";
  seriesLoadEnd: boolean = false;
  created() {
    this.getBrandList();
  }
  // 首字母查询，本地过滤
  handleFilterBrand(letter) {
    this.currentBrandLetter = letter;
    if (letter === "all") {
      this.brandList = this.brandListCody.slice();
    } else {
      this.brandList = this.brandListCody.slice().filter(item => {
        return item.initial.substr(0, 1) === letter;
      });
    }
    this.renderList = this.brandList;
  }
  // 获取车辆品牌列表
  getBrandList() {
    this.getvehicleTree().then(
      data => {
        this.brandList = data.data.list || [];
        this.brandListCody = this.brandList;
        this.renderList = this.brandList.map(item => {
          item.onlyCode = item.name;
          return item;
        });
        // this.handleFilterBrand(this.currentBrandLetter);
      },
      data => {
        this.brandList = [];
      }
    );
  }
  // 获取车系（含型号）
  getSeriesList(code) {
    this.seriesLoadEnd = true;
    this.getvehicleTree({ brandCode: code }).then(
      data => {
        let prevName = "";
        this.seriesList = data.data.list || [];
        for (let index = 0; index < this.seriesList.length; index++) {
          const element = this.seriesList[index];
          element.onlyCode = element.factoryName + element.name;
          if (prevName !== element.factoryName) {
            this.seriesList.splice(index, 0, {
              type: "factory",
              text: element.factoryName
            });
            prevName = element.factoryName;
          }
        }
        this.renderList = this.seriesList;
        this.seriesLoadEnd = false;
      },
      data => {
        this.seriesList = [];
        this.seriesLoadEnd = false;
      }
    );
  }
  // 处理车系或者车型选择
  handleVehicleSelect(item) {
    if (item.type === "factory") {
      return false;
    }
    switch (this.stepIndex) {
      case 0:
        this.getSeriesList(item.code);
        this.selectedFilterItem.push(Object.assign(item, { type: "brand" }));
        this.stepIndex = 1;
        break;
      case 1:
        if (this.selectType === "series" && !this.multiple) {
          this.selectedModelList = [];
          this.popoverVisible = false;
        }
        if (this.selectType === "model") {
          this.renderList = item.children.map(item => {
            item.name = item.modelDesc;
            item.onlyCode = item.code;
            return item;
          });
          this.selectedFilterItem.push(Object.assign(item, { type: "series" }));
          this.stepIndex = 2;
        } else {
          this.selectedModelList.push({
            name: this.selectedFilterItem[0].name + " / " + item.name,
            lastValue: item.code,
            valueGroup: [this.selectedFilterItem[0].code, item.code]
          });
        }
        break;
      default:
        if (!this.multiple) {
          this.selectedModelList = [];
          this.popoverVisible = false;
        }
        this.selectedModelList.push({
          name: this.selectedFilterItem[0].name + " / " + this.selectedFilterItem[1].name + " / " + item.name,
          lastValue: item.code,
          valueGroup: [this.selectedFilterItem[0].code, this.selectedFilterItem[1].code, item.code]
        });
    }
    this.updateData();
  }
  // emit 数据更改
  updateData() {
    this.$emit("update:value", this.selectedModelList);
    if (this.multiple) {
      this.$emit(
        "update:formatValue",
        this.selectedModelList.map(item => item.lastValue)
      );
    } else {
      if (this.selectedModelList[0]) {
        this.$emit("update:formatValue", this.selectedModelList[0].lastValue);
      } else {
        this.$emit("update:formatValue", "");
      }
    }
  }
  reset() {
    this.selectedModelList = [];
    this.$emit("update:value", this.selectedModelList);
    this.$emit("update:formatValue", "");
  }
  // 处理品牌和车系的条件删除
  handleRemoveFilterItem(item, i) {
    this.selectedFilterItem.splice(i, 1);
    if (item.type === "brand") {
      this.renderList = this.brandList;
      this.selectedFilterItem = [];
      this.stepIndex = 0;
    } else {
      this.renderList = this.seriesList;
      this.stepIndex = 1;
    }
  }
  // 展开popover
  expandSelector() {
    this.popoverVisible = true;
  }
  // 处理已选车型或者车系删除
  handleRemoveVehicle(i) {
    this.selectedModelList.splice(i, 1);
    this.updateData();
  }
  @Watch("defaultValue")
  WatchDefaultValue(val) {
    this.selectedModelList.unshift(...val);
    this.updateData();
  }
  handlePopShow() {
    if (this.selectedModelList instanceof Array && this.selectedModelList.length < 1) {
      this.stepIndex = 0;
      this.selectedFilterItem = [];
      this.getBrandList();
    }
  }
}
